import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {Helmet} from "react-helmet";
import Select from 'react-select';
import Loading from '../Loading';

const base = 'operation-dashboard';

@inject('OperationDashboardStore')
@observer
class OperationDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedAreas: [],
            selectedBusinessManagers: [],
            selectedTerritoryManagers: [],
            selectedOutlets: [],
            filteredClosedPopmealsStores: {},
            filteredClosedVirtualBrands: {},
            filteredNoOutletManagerClockedIn: [],
            filteredOutletWithLessMenuItem: [],
            filteredOutlets: []
        };
        this.interval = null;
    }

    componentDidMount() {
        const {
            OperationDashboardStore: {
                handleGetAreaByOutletData,
                handleGetOperationDashboardInfo
            }
        } = this.props;
        handleGetAreaByOutletData();

        this.interval = setInterval(() => {
            handleGetOperationDashboardInfo(this.state.filteredOutlets);
        }, 1000 * 60);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    handleFilterChange = (filterName, selectedOptions) => {
        this.setState({
            [filterName]: selectedOptions,
        });
    };

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.selectedAreas !== this.state.selectedAreas ||
            prevState.selectedBusinessManagers !== this.state.selectedBusinessManagers ||
            prevState.selectedTerritoryManagers !== this.state.selectedTerritoryManagers ||
            prevState.selectedOutlets !== this.state.selectedOutlets
        ) {
            this.filterStores();
        }
    }

    filterStores = () => {
        const { OperationDashboardStore } = this.props;
        const { areas, areaOutletMap, businessManagers, businessManagerOutletMap, 
            territoryManagers, territoryManagerOutletMap, outlets, handleGetOperationDashboardInfo } = OperationDashboardStore;
        // Get selected values
        let selectedAreas = this.state.selectedAreas.map((area) => area.value);
        if (selectedAreas.includes("All")) {
            selectedAreas = areas.map((area) => area.value);
        }
        const areaOutlets = selectedAreas.flatMap((area) => areaOutletMap[area] || []);

        let selectedBusinessManagers = this.state.selectedBusinessManagers.map((manager) => manager.value);
        if (selectedBusinessManagers.includes("All")) {
            selectedBusinessManagers = businessManagers.map((manager) => manager.value);
        }
        const businessManagerOutlets = selectedBusinessManagers.flatMap((manager) => businessManagerOutletMap[manager] || []);

        let selectedTerritoryManagers = this.state.selectedTerritoryManagers.map((manager) => manager.value);
        if (selectedTerritoryManagers.includes("All")) {
            selectedTerritoryManagers = territoryManagers.map((manager) => manager.value);
        }
        const territoryManagerOutlets = selectedTerritoryManagers.flatMap((manager) => territoryManagerOutletMap[manager] || []);

        let selectedOutlets = this.state.selectedOutlets.map((outlet) => outlet.value);
        if (selectedOutlets.includes("All")) {
            selectedOutlets = outlets.map((outlet) => outlet.value);
        }
        const outletValues = outlets.map((outlet) => outlet.value);

        const filteredOutlets = [
            ...(new Set(outletValues.filter(outlet =>
                (!selectedAreas.length || areaOutlets.includes(outlet)) &&
                (!selectedBusinessManagers.length || businessManagerOutlets.includes(outlet)) &&
                (!selectedTerritoryManagers.length || territoryManagerOutlets.includes(outlet)) &&
                (!selectedOutlets.length || selectedOutlets.includes(outlet))
            )))
        ];

        handleGetOperationDashboardInfo(filteredOutlets);
        this.setState({ filteredOutlets });
    };

    render() {
        const { OperationDashboardStore } = this.props;

        const {
            closedPopmealsStores,
            closedVirtualBrands,
            areas,
            businessManagers,
            territoryManagers,
            outlets,
            noOutletManagerClockedIn,
            outletWithLessMenuItem,
            loading,
            dashboardInfoLoading
        } = OperationDashboardStore;

        if (loading) {
            return <Loading />
        }

        const {
            selectedAreas,
            selectedBusinessManagers,
            selectedTerritoryManagers,
            selectedOutlets,
            filteredOutlets
        } = this.state;

        const filterApplied = Object.keys(this.state.selectedAreas).length > 0 ||
                Object.keys(this.state.selectedBusinessManagers).length > 0 ||
                Object.keys(this.state.selectedTerritoryManagers).length > 0 ||
                Object.keys(this.state.selectedOutlets).length > 0;

        return (
            <>
                <Helmet>
                    <title>Operation Dashboard | Outlet OS | Pop Meals</title>
                </Helmet>
                <div>
                    <div className={`${base}__filters`}>
                        <Select
                            isMulti
                            options={areas.slice()}
                            value={selectedAreas}
                            onChange={(selected) => this.handleFilterChange('selectedAreas', selected)}
                            placeholder="Select Areas"
                            className={`${base}__filter`}
                        />
                        <Select
                            isMulti
                            options={businessManagers.slice()}
                            value={selectedBusinessManagers}
                            onChange={(selected) => this.handleFilterChange('selectedBusinessManagers', selected)}
                            placeholder="Select Business Managers"
                            className={`${base}__filter`}
                        />
                        <Select
                            isMulti
                            options={territoryManagers.slice()}
                            value={selectedTerritoryManagers}
                            onChange={(selected) => this.handleFilterChange('selectedTerritoryManagers', selected)}
                            placeholder="Select Territory Managers"
                            className={`${base}__filter`}
                        />
                        <Select
                            isMulti
                            options={outlets.slice()}
                            value={selectedOutlets}
                            onChange={(selected) => this.handleFilterChange('selectedOutlets', selected)}
                            placeholder="Select Outlets"
                            className={`${base}__filter`}
                        />
                    </div>

                    {!filterApplied && (
                        <h1>
                            Select filters
                        </h1>
                    )}

                    {(filterApplied && dashboardInfoLoading) && (
                        <h1>
                            Loading...
                        </h1>
                    )}

                    {(filterApplied && !dashboardInfoLoading && filteredOutlets.length == 0) && (
                        <h1>
                            No outlet match the filters
                        </h1>
                    )}

                    {(filterApplied && !dashboardInfoLoading && filteredOutlets.length > 0) && (
                        <div className="row" style={{margin: '0px'}}> 
                            <div className="col-sm-4">
                                <div className={`${base}__card`} style={{marginLeft: '10px'}}>
                                    <div className={`${base}__card_header`}>Closed stores (Pop Meals)</div>
                                    {Object.keys(closedPopmealsStores).length > 0 ? (
                                        <ul>
                                            {Object.entries(closedPopmealsStores).map(([key, value]) => (
                                                <li key={key} style={{paddingRight: '6px', paddingBottom: '6px'}}>
                                                    {key} - {value.join(", ")}
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <p style={{margin: '15px'}}> No closed Closed Stores (Pop Meals) found. </p>
                                    )}
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className={`${base}__card`} style={{marginLeft: '10px'}}>
                                    <p className={`${base}__card_header`}>Closed stores (VB)</p>
                                    {Object.keys(closedVirtualBrands).length > 0 ? (
                                        <ul>
                                            {Object.entries(closedVirtualBrands).map(([key, value]) => (
                                                <li key={key} style={{paddingRight: '6px', paddingBottom: '6px'}}>
                                                    {key} - {value.join(", ")}
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <p style={{margin: '15px'}}> No closed Closed Stores (VB) found. </p>
                                    )}
                                </div> 
                            </div>
                            <div className="col-sm-4">
                                <div className={`${base}__card`} style={{marginLeft: '10px', marginRight: '10px'}}>
                                    <p className={`${base}__card_header`}>No manager clocked in</p>
                                    {noOutletManagerClockedIn.length > 0 ? (
                                        <ul>
                                            {noOutletManagerClockedIn.map((entry) => (
                                                <li style={{paddingRight: '6px', paddingBottom: '6px'}}>
                                                    {entry}
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <p style={{margin: '15px'}}> All outlet manager clocked in. </p>
                                    )}
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className={`${base}__card`} style={{marginLeft: '10px', marginRight: '10px'}}>
                                    <p className={`${base}__card_header`}>Outlets with Less than 10 meals on menu</p>
                                    {outletWithLessMenuItem.length > 0 ? (
                                        <ul>
                                            {outletWithLessMenuItem.map((entry) => (
                                                <li style={{paddingRight: '6px', paddingBottom: '6px'}}>
                                                    {entry}
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <p style={{margin: '15px'}}> All outlets have more than 10 meals on menu </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </>
        );
    }
}

export default OperationDashboard;
